@font-face {
  font-family: 'FiraCode';
  src: url('../../public/assets/FiraCode.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	margin: 0;
	padding: 0;
}

pre {
	font-family: inherit;
}

.showAlert {
	right: 0 !important;
}

.shake {
	animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@keyframes shake {
	10%,
	90% {
		transform: translateX(-1px);
	}

	20%,
	80% {
		transform: translateX(2px);
	}

	30%,
	50%,
	70% {
		transform: translateX(-4px);
	}

	40%,
	60% {
		transform: translateX(4px);
	}
}
